import React, { useState } from 'react';
import { FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { app, db } from '../../firebase'; // Ensure you have your firebase configurations here
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      await addDoc(collection(db, 'contacts'), {
        ...formData,
        timestamp: new Date(),
      });
      setSubmitStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="container mx-auto py-16 px-4 md:px-8">
      <motion.div
        className="text-center max-w-3xl mx-auto mb-16"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.h5 variants={itemVariants} className="text-primary text-lg font-bold uppercase tracking-wider mb-2">Contact Us</motion.h5>
        <motion.h1 variants={itemVariants} className="text-4xl md:text-5xl font-bold leading-tight text-gray-800">If You Have Any Query, Feel Free To Contact Us</motion.h1>
      </motion.div>
      
      <motion.div
  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-20"
  variants={containerVariants}
  initial="hidden"
  animate="visible"
>
  {[
    { icon: FaPhoneAlt, title: 'Call to ask any question', content: '+012 345 6789' },
    { icon: FaEnvelopeOpen, title: 'Email to get free quote', content: 'INFO@MARKATLASINKJETTECHNOLOGIES.COM' },
    { icon: FaMapMarkerAlt, title: 'Visit our office', content: 'UPPAL, HYDERABAD, TELANGANA' },
  ].map((item, index) => (
    <motion.div
      key={index}
      className="bg-white shadow-lg rounded-lg p-6 transition duration-300 hover:shadow-xl transform hover:-translate-y-1"
      variants={itemVariants}
    >
      <div className="flex items-center">
        <div className="bg-primary text-black flex items-center justify-center rounded-full w-5 h-16 flex-shrink-0 transition duration-300 hover:bg-primary-dark">
          <item.icon className="text-2xl" />
        </div>
        <div className="ml-6">
          <h5 className="text-lg font-semibold mb-1 text-gray-800">{item.title}</h5>
          <h4 className={`text-xs ${item.icon === FaEnvelopeOpen ? 'text-20px' : ''} text-gray-600 mb-1`}>
            {item.content}
          </h4>
        </div>
      </div>
    </motion.div>
  ))}
</motion.div>


      <div className="flex flex-wrap -mx-4">
        <motion.div
          className="w-full md:w-1/2 px-4 mb-8 md:mb-0"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <form className="bg-white shadow-lg rounded-lg p-8" onSubmit={handleSubmit}>
            <div className="space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="form-input w-full bg-gray-100 px-4 py-3 rounded-md focus:ring-2 focus:ring-primary transition duration-300 hover:bg-gray-200"
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="form-input w-full bg-gray-100 px-4 py-3 rounded-md focus:ring-2 focus:ring-primary transition duration-300 hover:bg-gray-200"
                  placeholder="Your Email"
                  required
                />
              </div>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                className="form-input w-full bg-gray-100 px-4 py-3 rounded-md focus:ring-2 focus:ring-primary transition duration-300 hover:bg-gray-200"
                placeholder="Subject"
                required
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="form-textarea w-full bg-gray-100 px-4 py-3 rounded-md focus:ring-2 focus:ring-primary transition duration-300 hover:bg-gray-200"
                rows="4"
                placeholder="Message"
                required
              ></textarea>
              <motion.button
                className={`btn bg-primary text-black px-8 py-3 rounded-md w-full transition duration-300 ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-dark transform hover:-translate-y-1'
                }`}
                type="submit"
                disabled={isSubmitting}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </motion.button>
              {submitStatus === 'success' && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-green-600 text-center font-semibold"
                >
                  Message sent successfully!
                </motion.p>
              )}
              {submitStatus === 'error' && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-red-600 text-center font-semibold"
                >
                  Error sending message. Please try again.
                </motion.p>
              )}
            </div>
          </form>
        </motion.div>
        <motion.div
          className="w-full md:w-1/2 px-4"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-2 h-full">
            <iframe
              className="w-full h-full rounded-md"
              src="https://www.google.co.in/maps/embed?pb=!1m18!1m12!1m3!1d3916.328517342021!2d78.5528739!3d17.3998302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99002df124d5%3A0x4c8e40380c7e02ae!2sMarkatlas%20Inkjet%20Technologies%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1646398828586!5m2!1sen!2sin"
              frameBorder="0"
              style={{ border: '0', minHeight: '400px' }}
              allowFullScreen
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactUs;